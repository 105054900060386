
@import "src/styles/mixins";

.block {
  display: flex;
  font-size: 18px;
  line-height: 34px;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1 1;
  overflow: hidden;

  @include mobile {
    font-size: 16px;
    line-height: 24px;
  }

  &__table {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;

    @include tablet {
      overflow-x: auto;
      padding: 0 50px;
    }

    @include mobile {
      padding: 0 25px;
    }
  }

  &__head {
    display: grid;
    text-align: center;
    grid-template-columns: 100px 120px 1fr 100px 120px;
    padding: 8px 20px;
    color: #00D1FF;

    @include mobile {
      padding: 10px;
      width: 600px;
      box-sizing: border-box;
    }


    & > div {
      padding: 0 20px;
      white-space: nowrap;

      @include mobile {
        padding: 0 10px;
      }

      &:nth-child(3) {
        text-align: left;
      }
    }
  }

  &__rows {
    display: block;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: auto;
    padding: 15px 0;

    @include tablet {
      overflow: hidden;
    }

    @include mobile {
      padding: 10px 0;
      width: 600px;
      box-sizing: border-box;
    }

    .row {
      margin: 0 20px;
      padding: 4px 0;
      text-align: center;
      display: grid;
      grid-template-columns: 100px 120px 1fr 100px 120px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      @include mobile {
        margin: 0 10px;
      }

      &:last-child {
        border-bottom: none;
      }

      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0px 20px;

        @include mobile {
          padding: 0 10px;
        }

        &.gray {
          color: rgba(255, 255, 255, 0.5);
        }

        &.green {
          color: #00FF19;
        }

        &:nth-child(3) {
          text-align: left;
        }
      }
    }
  }

  &__footer {
    margin-top: 20px;

    @include tablet {
      margin: 20px 50px 0;
    }

    @include mobile {
      margin: 20px 25px 0;
    }

    span {
      display: block;
      text-align: center;
      padding: 11px 0;
      font-size: 18px;
      line-height: 34px;

      @include mobile {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
