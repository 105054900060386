
@import "src/styles/mixins";

.bars {
  --bars-width: 100px;
  --bars-height: 150px;
  --bars-bar-collapsed: 30px;
  --bars-bar-size: 20px;
  --bars-bar-thickness: 2px;
  --bars-offset: 100px;

  @include tablet {
    --bars-width: 50px;
    --bars-height: 100px;
    --bars-bar-collapsed: 20px;
    --bars-bar-size: 24px;
    --bars-bar-thickness: 2px;
    --bars-offset: 50px;
  }

  @include mobile {
    --bars-bar-thickness: 1px;
    --bars-offset: 25px;
  }


  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: calc(50% - var(--bars-height) / 2);
  right: var(--bars-offset);
  width: var(--bars-width);
  height: var(--bars-height);

  em {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--bars-bar-collapsed);
    height: var(--bars-bar-size);
    cursor: pointer;
    transition: 500ms ease all;

    &:after {
      display: block;
      content: '';
      width: 100%;
      height: var(--bars-bar-thickness);
      background: rgba(255, 255, 255, 0.4);
      transition: 500ms ease all;
    }

    &.active {
      width: 100%;

      &:after {
        background: #00D1FF;
      }
    }
  }

}
