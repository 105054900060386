@import "src/styles/mixins";

.block {
  display: flex;
  height: 56px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: text;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);

  @include mobile {
    height: 44px;
    font-size: 18px;
    line-height: 34px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    pointer-events: none;

    .icon {
      height: 30px;
      user-select: none;

      @include mobile {
        height: 22px;
      }
    }

    .spacer {
      position: relative;
      white-space: nowrap;
      color: transparent;
    }

  }

  input {
    position: absolute;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    border: none;
    top: 0;
    left: 0;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    outline: none;
    text-align: center;
    padding: 0 0 0 30px;
  }


}
