@import "src/styles/mixins";

.block {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  &__wrapper {
    margin: 50px auto 0;
    max-width: 1500px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    font-size: 18px;
    line-height: 34px;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    box-sizing: border-box;

    @include mobile {
      max-width: none;
      margin: 38px 0 0;
      padding: 0 25px;
      font-size: 14px;
      line-height: 18px;
    }

    span {
      color: #fff;
    }

    b {
      font-weight: normal;

      @include mobile {
        display: none;
      }
    }

    svg {
      width: 34px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & > div {
      flex: 1 1;
      text-align: left;
      margin-right: 40px;

      &:nth-child(2) {
        text-align: right;
        margin-left: 40px;
        margin-right: 0;
      }
    }
  }

}

.wallet {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  @include mobile {
    padding: 3px 8px;
    gap: 5px;
  }

  &:before, &:after {
    display: block;
    content: '';
  }

  &:before {
    width: 6px;
    height: 6px;
    background: #00FF19;
    border-radius: 100%;
  }

  &:after {
    border: 1px solid #fff;
    width: 8px;
    height: 8px;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(-45deg);
    margin: 0 10px 0 0;
  }

  span {
    display: block;
    max-width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mobile {
      max-width: 70px;
    }
  }
}
