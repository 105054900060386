@import "src/styles/mixins";

.block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;

  &__live {
    position: relative;
    padding: 7px 20px 7px 61px;
    font-size: 18px;
    line-height: 34px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);

    @include tablet {
      margin: 0 50px;
    }

    @include mobile {
      margin: 0 25px;
      font-size: 16px;
      line-height: 24px;
      padding: 7px 20px 7px 45px;
    }

    em {
      position: absolute;
      display: block;
      width: 30px;
      height: 30px;
      top: 9px;
      left: 20px;
      box-sizing: border-box;

      @include mobile {
        top: 4px;
        left: 7px;
      }

      i {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 100%;
        background: transparent;
        transform-style: preserve-3d;

        animation: online-circle 2000ms linear infinite;

        &:nth-child(2) {
          animation-delay: 1000ms;
        }

        &:nth-child(3) {
          animation: none;
          width: 4px;
          height: 4px;
          top: calc(50% - 2px);
          left: calc(50% - 2px);
          background: #00FF19;
        }
      }
    }
  }
}

@keyframes online-circle {
  0% {
    transform: scale(0.1);
    border-color: #fff;
  }
  50% {
    border-color: #fff;
    transform: scale(0.7);
  }
  80% {
    border-color: transparent;
    transform: none;
  }
}
