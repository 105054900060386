
@import "src/styles/mixins";

.slide {
  --color-accent: #9cd7db;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  @include content-padding;
  box-sizing: border-box;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background 400ms ease;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%);

      @include tablet {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 80%);
      }
    }

    canvas {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    em {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      transition: opacity 400ms ease;
    }

    &.active em {
      opacity: 0.3;
    }
  }



  &__text {
    position: absolute;
    top: 0;
    right: calc(100% - var(--polygon-offset-left));
    height: 100%;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    transition: all 400ms ease;
    transform-style: preserve-3d;
    padding: 0 100px;
    transform: translateX(-40px);

    @include tablet {
      display: none;
    }

    @include mobile {
      display: none;
    }

    div {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 32px;

      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          transform: translateX($i * 10px);
        }
      }
    }

    &.hidden {
      opacity: 0;
      transform: translate(-40px, 30px);
    }
  }

  &__title {
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    margin-bottom: 30px;

    div:nth-child(1) {
      color: var(--color-accent);
      font-size: 30px;
    }

    @include tablet {
      margin-top: 110px;
      font-size: 50px;
      line-height: 50px;

      div:nth-child(1) {
        font-size: 26px;
      }
    }

    @include mobile {
      font-size: 34px;
      line-height: 40px;
      div:nth-child(1) {
        font-size: 20px;
      }
    }

  }

  @include tablet {
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  &.right {
    align-items: flex-end;
    --color-accent:#9cd7db;

    @include tablet {
      justify-content: flex-end;
    }
  }

  &.right &__image:after {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%);

    @include tablet {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 80%);
    }
  }

  &.right &__text {
    right: auto;
    left: var(--polygon-offset-right);
    align-items: flex-start;
    transform: none;

    &.hidden {
      transform: translateY(-30px);
    }
  }

  &.right &__title {
    align-items: flex-end;

    @include tablet {
      align-items: flex-start;
    }
  }

}
