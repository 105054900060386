

@import "src/styles/mixins";

.screen {
  --polygon-center: 50%;
  --polygon-skew-size: 200px;
  --polygon-offset-left: 90%;
  --polygon-offset-right: 10%;
  --polygon-offset-hint: 2%;
  position: relative;
  box-sizing: border-box;

  @include tablet {
    --polygon-skew-size: 100px;
  }

  @include mobile {
    --polygon-skew-size: 70px;
  }

  &.leftHint {
    //--polygon-center: calc(50% - var(--polygon-offset-hint));
  }

  &.rightHint {
    //--polygon-center: calc(50% + var(--polygon-offset-hint));
  }

  &.left {
    --polygon-center: var(--polygon-offset-left);
  }

  &.right {
    --polygon-center: var(--polygon-offset-right);
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--full-height);
    transition: clip-path 400ms ease;

    &.clipped {
      clip-path: polygon(calc(var(--polygon-center) - var(--polygon-skew-size)) 0%, 100% 0%, 100% 100%, calc(var(--polygon-center) + var(--polygon-skew-size)) 100%);

      @include tablet {
        clip-path: polygon(100% calc(var(--polygon-center) - var(--polygon-skew-size)), 100% 100%, 0% 100%, 0% calc(var(--polygon-center) + var(--polygon-skew-size)));
      }
    }
  }

  &__helper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 260px;
    position: absolute;
    top: calc(var(--full-height) / 2);
    left: var(--polygon-center);
    transform: translate(-50%, -50%);
    transition: all 400ms ease;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.25) 36.98%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;

    @include tablet {
      display: none;
    }

    &.hidden {
      transform: translate(-50%, -50%) scale(0.4);
      opacity: 0;
    }

    .chevron {
      width: 50%;
      flex: 1 1 50%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;


      &.invert {
        transform: rotate(180deg);
      }

      svg path {
        opacity: 0.4;
        animation: chevron-pulse ease 2000ms infinite;

        &:nth-child(1) {
          animation-delay: 500ms;
        }

        &:nth-child(2) {
          animation-delay: 700ms;
        }

        &:nth-child(3) {
          animation-delay: 900ms;
        }
      }
    }
  }

}

@keyframes chevron-pulse {
  0% {
    opacity: 0.4;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}
