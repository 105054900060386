@import 'src/styles/mixins';

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 48px;
  left: var(--content-padding-side);
  width: calc(100% - var(--content-padding-side) * 2);
  height: 60px;

  @include tablet {
    top: 40px;
  }

  @include mobile {
    top: 30px;
  }

  &__logo {

  }

  &__left, &__right {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }

  &__right {
    justify-content: flex-end;
  }

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    height: 100%;
    margin-right: 20px;
    cursor: pointer;
    transition: 200ms ease all;

    &:hover {
      color: #00D1FF;
    }

    &.small-margin {
      margin-right: 10px;
    }

    &.icon {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      padding: 0;
      border: 1px solid rgba(255, 255, 255, 0.4);

      @include tablet {
        width: 50px;
        height: 50px;
      }

      @include mobile {
        width: 40px;
        height: 40px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }


}
