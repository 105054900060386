
@import "src/styles/mixins";

.screen {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  height: var(--full-height);
  box-sizing: border-box;
  perspective: 1000px;
  @include content-padding;

  &__entry {
    transform-style: preserve-3d;
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;

    & > img {
      position: relative;
      object-fit: cover;
      display: block;
    }

    & > video {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}
