
@import "src/styles/mixins";

.screen {
  @include content-padding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__discord {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      font-size: 50px;
      line-height: 50px;
    }

    @include mobile {
      font-size: 38px;
      line-height: 40px;
    }

    a, b {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      text-decoration: none;

      span {
        display: block;

        @include mobile {
          width: 200px;
        }
      }

      em {
        display: block;
        width: 140px;
        height: 140px;
        box-sizing: border-box;
        margin-bottom: 40px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 500px;

        @include tablet {
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
        }

        @include mobile {
          width: 80px;
          height: 80px;
          margin-bottom: 20px;
        }
      }
    }
    a {
      cursor: pointer;
    }
  }

  &__email {
    margin-top: 64px;
    @include tablet {
      margin-top: 56px;
      max-width: 75%;
    }
    @include mobile {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      align-items: center;

      & > span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #00D1FF;
        text-transform: lowercase;
        margin-bottom: 10px;
      }
    }



    & > label {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 22px;
      display: inline-block;
      line-height: 150%;
      margin-bottom: 10px;
    }

    &_form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;



      & > input {
        padding: 8px 16px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        outline: none;
        background: none;
        color:#ffffff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        width: 73%;
        height: calc(56px - 18px);
        margin-right: 10px;


        @include tablet {
          width: 65%;
        }


        &::placeholder {
          color: rgba(255,255,255,0.4);
        }
      }
    }


    & button {
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background: none;
      width: 20%;
      height: 56px;
      outline: none;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      transition: color 0.5s ease;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        display: block;
      };

      &:hover {
        color: #00D1FF;
      }

      @include tablet {
        width: 25%;
        min-width: 108px;
      }

      @include mobile {
        width: 120%;
        font-size: 16px;
        margin-top: 20px;
        height: auto;
      }

    }

    &_error {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #FF0000;
      text-align: left;
      margin-top: 8px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease;

      &.active {
        transition: opacity 0.5s ease;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__footer {
    flex: 0 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 34px;
    color: rgba(255, 255, 255, 0.4);

    @include tablet {
      flex-direction: column-reverse;
    }


    .block {
      display: block;
      flex: 1 1;
      text-align: left;

      &:nth-child(2) {
        text-align: center;
        flex: 0 0;
      }

      &:nth-child(1) {
        @media screen and (max-width: 400px) {
          font-size: 14px;
        }


      }

      @include tablet {
        margin-top: 10px;
        text-align: center;
        font-size: 18px;
        line-height: 34px;
      }

      @include mobile {
        font-size: 16px;
        line-height: 24px;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      svg {
        opacity: 0.4;
        margin-right: 10px;
      }
    }
  }
}

