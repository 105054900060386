
@import "src/styles/mixins";

.block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
  gap: 20px;
  box-sizing: border-box;
  width: 100%;

  @include mobile {
    padding: 15px 10px;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 5px;

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    .item {
      display: block;

      b, span {
        display: block;
        text-align: center;
      }


      b {
        font-weight: normal;
        font-size: 34px;
        line-height: 40px;

        @include mobile {
          font-size: 30px;
          line-height: 34px;
        }

        &.good {
          color: #00FF19;
        }

        &.bad {
          color: #FF2734;
        }
      }

      span {
        font-size: 18px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.5);

        @include mobile {
          font-size: 16px;
          line-height: 20px;
        }
      }

    }
  }

}
