
.bars {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 2px;

  em {
    display: block;
    width: 2px;
    height: 2px;
    min-height: 2px;
    background: #fff;
    transition: 500ms ease all;
  }

}
