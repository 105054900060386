
@import "src/styles/mixins";

.menu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--full-height);
  background: #000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 500ms ease all;
  overflow: hidden;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    & > a {
      transform: translateY(30px);
    }

    .coming {
      transform: translateY(30px);

    }

  }

  & > a {
    font-size: 22px;
    line-height: 32px;
    color: #fff;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    transition: transform 600ms ease;

    &.social {
      color: #fff;
    }
  }

  .spacer {
    display: block;
    height: 60px;
  }

  &__close {
    display: flex;
    position: fixed;
    top: 48px;
    right: var(--content-padding-side);
    height: 60px;
    align-items: center;
    justify-content: flex-end;

    a {
      position: relative;
      display: block;
      width: 35px;
      height: 35px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 100px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    @include tablet {
      top: 40px;

      a {
        width: 50px;
        height: 50px;
      }
    }

    @include mobile {
      top: 30px;

      a {
        width: 40px;
        height: 40px;
      }
    }

  }

  .coming {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    transition: transform 0.6s ease;

    svg {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }

    &__text {
      text-transform: uppercase;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 32px;
      text-align: center;
      max-width: 60%;
    }

  }
}


.email {

    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 80%;

    & > span {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #00D1FF;
      text-transform: lowercase;
      margin-bottom: 10px;
    }




  & > label {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  &_form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;



    & > input {
      padding: 8px 16px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      outline: none;
      background: none;
      color: #FFFFFF;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      width: calc(100% - 34px);
      height: calc(56px - 18px);
      margin-bottom: 10px;


      &::placeholder {
        color: #FFFFFF;
        text-align: center;
      }
    }
  }


  & button {
    padding: 8px 16px;
    border: 2px solid #00D1FF;
    background: #00D1FF;
    width: 100%;
    height: 56px;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color:#fff;
    transition: color 0.5s ease;
    cursor: pointer;


    &:hover {
        color: #00D1FF;
    }
  }

  &_error {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FF0000;
    text-align: center;
    margin-top: 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease;

    &.active {
      transition: opacity 0.5s ease;
      opacity: 1;
      visibility: visible;
    }
  }
}
