
@mixin content-padding {
  padding: var(--content-padding-top) var(--content-padding-side);
  box-sizing: border-box;
  width: 100%;
  min-height: var(--full-height);
}

@mixin tablet() {
  @media (max-width: 900px) {
    @content
  }
}

@mixin mobile() {
  @media (max-width: 450px) {
    @content
  }
}
