
@import "variables";
@import "fonts";
@import "mixins";

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  background: #333;
  color: #fff;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  --full-height: 100vh;
  --content-padding-side: 100px;
  --content-padding-top: 60px;

  @include tablet() {
    --content-padding-side: 50px;
    --content-padding-top: 50px;
  }

  @include mobile() {
    --content-padding-side: 35px;
    --content-padding-top: 30px;
  }
}
