
@import "src/styles/mixins";

.progress {
  --progress-size: 100px;
  --progress-offset-bottom: 60px;
  --progress-offset-center: calc(50% - var(--progress-size) / 2);
  --progress-offset-right: var(--progress-offset-center);
  --progress-font-size: 14px;
  --progress-font-line: 18px;

  @include tablet {
    --progress-offset-bottom: 50px;
    --progress-offset-right: 50px;
    --progress-offset-center: 50px;
    --progress-size: 50px;
  }

  @include mobile {
    --progress-offset-bottom: 30px;
    --progress-offset-right: 25px;
    --progress-offset-center: 25px;
    --progress-size: 40px;
  }

  display: block;
  position: fixed;
  bottom: var(--progress-offset-bottom);
  right: var(--progress-offset-center);
  width: var(--progress-size);
  height: var(--progress-size);
  transition: all 800ms ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 500px;

  &.right {
    right: var(--progress-offset-right);
  }

  &.hidden {
    transition-duration: 400ms;
    transform: scale(0);
    visibility: hidden;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }

  &__hint, &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 400ms ease;
    text-transform: uppercase;
    font-size: var(--progress-font-size);
    line-height: var(--progress-font-line);
    color: rgba(255, 255, 255, 0.4);

    span {
      color: #00D1FF;
    }

    &.hidden {
      opacity: 0;
      transform: scale(0.1);
      visibility: hidden;
    }

    svg {
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
    }
  }

}
