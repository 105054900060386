@import "src/styles/mixins";

.logo {
  display: block;
  width: 240px;
  height: 60px;
  position: relative;
  cursor: pointer;

  @include mobile {
    width: 60px;
  }

  &__icon, &__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease;
  }

  &__icon {
    &.hidden {
      transform: translateY(10px);
      opacity: 0;
    }

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__text {
    &.hidden {
      transform: translateY(-10px);
      opacity: 0;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

}
