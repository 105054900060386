
@import "src/styles/mixins";

.screen {
  @include content-padding;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;

  &__text {
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    text-transform: uppercase;
    transition: 400ms ease-out all;
    margin-bottom: 60px;

    @include tablet {
      font-size: 50px;
      line-height: 50px;
      margin-bottom: 40px;
    }

    @include mobile {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    div.accent {
      color: #00D1FF;
    }

    p {
      display: block;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 32px;
      text-transform: none;
      max-width: 500px;

      @include tablet {
        font-size: 20px;
        line-height: 30px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &.hidden {
      opacity: 0;
      transition-timing-function: ease-in;
      transform: translateX(50px);
    }
  }

}
