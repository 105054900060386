
.overlay {
  --ring-size: 100px;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--full-height);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms ease-in-out all, 0ms height linear;
  user-select: none;

  &__center {
    --progress-stroke: #fff;
    display: flex;
    position: relative;
    width: var(--ring-size);
    height: var(--ring-size);
    align-items: center;
    justify-content: center;
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border-radius: 100%;
    overflow: hidden;

    &.active:hover {
      cursor: pointer;
      --progress-stroke: #00D1FF;
    }

    span {
      color: #00D1FF;
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: rotate(-90deg);

      circle {
        fill: none;
        transition: stroke-dasharray 200ms ease, stroke 300ms ease;
      }
    }

    .title {
      display: block;
      transition: all 600ms ease;
      transition-delay: 400ms;
      transform-style: preserve-3d;

      &.hidden {
        transform: scale(0.8);
        opacity: 0;
      }
    }

    .logo {
      display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      transition: 800ms ease all;

      &.hidden {
        transform: scale(0);
        opacity: 0;
      }

      svg {
        width: 34px;
        height: 34px;

        path {
          animation: logo-animation 2000ms ease infinite;
          transform-origin: 50% 50%;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    transition: all 900ms ease;
    transition-delay: 700ms;

    &.hidden {
      opacity: 0;
      transform: translateY(30px);
    }

    svg {

    }

    p {
      display: block;
      text-transform: uppercase;
      text-align: center;

      a {
        color: #00D1FF;
        text-decoration: none;
        opacity: 0.3;
        transition: 200ms ease all;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

@keyframes logo-animation {
  0% {
    opacity: 1;
    stroke-dasharray: 0 105.6px;
    transform: none;
  }
  60% {
    stroke-dasharray: 105.6px 105.6px;
    opacity: 1;
    transform: none;
  }
  80% {
    stroke-dasharray: 105.6px 105.6px;
    opacity: 1;
    transform: none;
  }
  98% {
    opacity: 0;
    transform: rotate(30deg);
  }
  100% {
    opacity: 0;
    transform: rotate(30deg);
  }
}
