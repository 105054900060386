
.line {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  height: var(--full-height);
  pointer-events: none;

  &.mirror {
    transform: translateX(100%) scale(-1, 1);
  }

  path {
    transition: all 800ms cubic-bezier(0.65, 0, 0.35, 1);
  }
}
