@import "src/styles/mixins";

.wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 25px;
  z-index: 2;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: 300ms ease;
  transition-property: opacity, visibility;
  box-sizing: border-box;

  &.active {
    visibility: visible;
    opacity: 1;

    .modal {
      transform: none;
    }
  }

}

.modal {
  position: relative;
  box-sizing: border-box;
  max-width: 470px;
  width: 100%;
  padding: 40px 75px;
  background: linear-gradient(53.83deg, #2B1539 -22.66%, #30145C 138.58%), #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  transform: translateY(100%);
  transition: transform 300ms ease;

  @include mobile {
    padding: 50px 20px 20px;
  }

  h3 {
    font-weight: 400;
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 30px;
    padding: 0;
    text-transform: uppercase;

    @include mobile {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__corner {
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 68px;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      width: 58px;
      height: 58px;
    }

    &.right {
      left: auto;
      right: 0;
    }

    .hint {
      cursor: default;
      position: absolute;
      top: -50px;
      left: calc(100%);
      width: 260px;
      background: #000;
      font-size: 18px;
      line-height: 26px;
      font-family: "Montserrat", sans-serif;
      text-align: left;
      padding: 25px 30px;
      box-sizing: border-box;
      border: 1px solid #57446b;
      transition: all 200ms ease;
      opacity: 0;
      visibility: hidden;
      transform: translateX(30px);

      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 72px;
        left: -11px;
        width: 20px;
        height: 20px;
        border: 1px solid #57446b;
        background: #000;
        transform: rotate(45deg);
        border-right-color: transparent;
        border-top-color: transparent;

        @include mobile {
          top: 70px;
          left: -8px;
          width: 15px;
          height: 15px;
        }
      }
    }

    &:hover .hint {
      opacity: 1;
      visibility: visible;
      transform: none;
    }

  }
}

.button {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 34px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 20px;
  height: 56px;
  margin-bottom: 12px;
  cursor: pointer;

  @include mobile {
    font-size: 16px;
    line-height: 24px;
    height: 44px;

    svg {
      width: 28px;
    }
  }

  &.gray {
    border-color: transparent;
    color: rgba(255, 255, 255, 0.5);
  }
}
