
@import "src/styles/mixins";

.page {
  height: 100%;
  box-sizing: border-box;
  background: url('../../../assets/images/space_blur.jpg') no-repeat center;
  background-size: cover;

  &__wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 1500px;
    padding: 140px 50px 40px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    .column {
      width: 50%;

      @include tablet {
        width: 100%;
      }
    }

    @include tablet {
      display: block;
      padding: 140px 0 40px;
      overflow-y: auto;
    }

    @include mobile {
      padding: 100px 0 20px;
    }
  }
}
