@import "src/styles/mixins";

.button {
  display: block;
  height: 56px;
  color: #fff;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  width: 100%;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);

  @include mobile {
    height: 44px;
    font-size: 16px;
    line-height: 24px;
  }

  &.compact {
    max-width: 320px;
  }
}
