
@import "src/styles/mixins";

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 60px 90px;
  margin-right: 80px;
  gap: 20px;
  min-height: 380px;

  @include tablet {
    margin: 0 50px 50px;
  }

  @include mobile {
    margin: 0 25px 55px;
    padding: 40px 45px;
    min-height: 260px;
    gap: 12px;
  }

  &__vector {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    bottom: 0;

    &.right {
      transform: scaleX(-1);
      left: auto;
      right: 0;
    }
  }

  h1 {
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 60px;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 0;

    @include mobile {
      font-size: 28px;
      line-height: 30px;
    }
  }

  h2 {
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    margin: 0;
    padding: 0;

    @include mobile {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @include mobile {
      font-size: 16px;
      line-height: 20px;
    }

    i {
      font-style: normal;
      color: rgba(255, 255, 255, 0.5);
    }

    span {
      color: #00d1ff;
    }
  }

  .boxes {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 180px;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #00FF19;

    @include mobile {
      height: auto;
      flex-direction: column;
    }

    &.bad {
      color: #FF2734;
    }

    img {
      width: 50%;
      object-fit: cover;

      @include mobile {
        width: auto;
      }
    }

    div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 18px;
      line-height: 26px;

      @include mobile {
        width: auto;
        padding: 15px 0;
      }

      span {
        font-size: 34px;
        line-height: 40px;
      }

    }
  }
}
