
.roller {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: var(--full-height);

  &__slide {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: var(--full-height);
    transform-style: preserve-3d;
    will-change: transform;
    transform: translateY(-100%);
  }
}
