
@import "src/styles/mixins";

.screen {
  @include content-padding;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &__text {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    font-size: 130px;
    line-height: 130px;
    text-transform: uppercase;

    @include tablet {
      font-size: 80px;
      line-height: 80px;
    }

    @include mobile {
      font-size: 44px;
      line-height: 46px;
      margin-bottom: 50px;
    }

    em {
      display: flex;
      font-style: normal;
      align-items: flex-start;
      justify-content: flex-start;

      span {
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: #00D1FF;
        margin-left: 30px;

        @include tablet {
          font-size: 20px;
          line-height: 24px;
        }

        @include mobile {
          font-size: 16px;
          line-height: 18px;
          margin-left: 25px;
        }
      }

      &.accent {
        color: #00D1FF;
      }
    }
  }
}
