
@import "src/styles/mixins";

.screen {
  @include content-padding;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  &__container {
    flex: 1 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    transition: 400ms ease-out all;

    @include tablet {
      font-size: 20px;
      line-height: 30px;
    }

    @include mobile {
      font-size: 14px;
      line-height: 22px;
      margin-right: 30px;
    }

    &.hidden {
      opacity: 0;
      transition-timing-function: ease-in;
      transform: translateY(30px);
    }

    &.bold {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

}
