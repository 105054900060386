
.screen {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) and (orientation: landscape){ /* tablet and smaller */
    display: flex;
  }

  &__text {
    font-size: 16px;
    text-align: center;
    width: 280px;
  }
}
