
.graphics {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--full-height);
  overflow: hidden;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
