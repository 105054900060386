@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.eot');
  src: url('../assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../assets/fonts/Montserrat-Regular.woff') format('woff'),
  url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('../assets/fonts/JosefinSans-Bold.eot');
  src: url('../assets/fonts/JosefinSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/JosefinSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/JosefinSans-Bold.woff') format('woff'),
  url('../assets/fonts/JosefinSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

